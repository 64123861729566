.mentionSuggestions {
  border: 1px solid rgb(75, 74, 74);
  margin-top: 0.4em;
  position: absolute;
  min-width: 220px;
  max-width: 440px;
  background: #282c34;
  border-radius: 2px;
  box-shadow: none;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transform: scale(0);
}
.mentionSuggestionsEntry {
  padding: 0.2rem;
} 
.mentionSuggestionsEntry:active {
  background-color: #3d3d3d;
  padding: 0.2rem;
} 

.mentionSuggestionsEntryFocused {
  padding: 0.2rem;
  background-color: #7a7a7a;
}
